import React, { useEffect, useState } from "react";
import { Select } from "antd";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";

interface theProps {
  productId?: string;
  editCategoryName?: string;
  onChange: (selected: any) => void;
}

const ProductCategorySelector = ({
  onChange,
  editCategoryName,
  productId,
}: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getAllCategories = async () => {
      setIsFetching(true);
      try {
        const response = await BaseService.get_api(
          `${global_variables.product_categories}`
        );
        if (response?.data) {
          const arrangedResponse = response?.data?.payload.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }));

          setAllCategories(arrangedResponse);
          setIsFetching(false);
        }
      } catch (error) {
        console.log("prodCatErr:", error);
      } finally {
        setIsFetching(false);
      }
    };

    getAllCategories();
  }, []);

  useEffect(() => {
    if (editCategoryName) {
      setSelectedCategory(editCategoryName);
    }
  }, [editCategoryName]);

  const handleChange = (value: string) => {
    setSelectedCategory(value);
    onChange(value);
  };

  return (
    <>
      <Select
        id="category"
        value={selectedCategory}
        className="h-[40px] w-full"
        options={allCategories}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Product category"
        allowClear
        virtual={false}
      />
    </>
  );
};

export default ProductCategorySelector;
