import { useEffect, useState, useCallback, useReducer } from "react";
import { Input, message, Modal, Select, Spin } from "antd";
import ProductCategorySelector from "../../../components/shared/productCategorySelector";
import BaseService from "../../../helpers/baseServices";
import MultipleSelect from "../../../components/shared/MultipleSelect";
import SelectOnyCountry from "../../../components/shared/SelectOnyCountry";
import UploadComponent from "../ImageBillBoard";
import SmallUploadOne from "../smallUploadOne";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoCloseOutline } from "react-icons/io5";
import ProductCatMultiSelect from "../../../components/shared/productCatMultiSelect";

interface modalProps {
  isOpened: boolean;
  productId?: string;
  handleClose: () => void;
  handleReFetch: () => void;
  DispatchImages: any;
}

const initialValues = {
  imageUrls: [],
  items: [
    {
      id: "item-1",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-2",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-3",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-4",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
  ],
};

const ReducerFunction = (state: any, action: any) => {
  switch (action.type) {
    case "littleOnes": {
      const { url, filename, id } = action.payload;
      const newImageUrls = [...state.imageUrls, { url, filename }];

      const urlMap = new Map(newImageUrls.map((item) => [item.url, item]));
      const uniqueImageUrls = Array.from(urlMap.values());

      const updatedItems = state.items.map((item: any) => {
        if (item.id === id) {
          return { ...item, url, filename };
        }
        return item;
      });

      return {
        ...state,
        imageUrls: uniqueImageUrls,
        items: updatedItems,
      };
    }

    case "editStated": {
      const filenameToRemove = action.payload;

      return {
        ...state,
        imageUrls: [...filenameToRemove],
      };
    }

    case "newItems": {
      const myItems = action.payload;
      return {
        ...state,
        items: [...myItems],
      };
    }

    case "updateItems": {
      const myItems = action.payload;
      return {
        ...state,
        items: [...myItems],
      };
    }

    default:
      return state;
  }
};

const CreateHardwareProduct = ({
  DispatchImages,
  productId,
  isOpened,
  handleClose,
  handleReFetch,
}: modalProps) => {
  const today = new Date();
  const [isBusy, setIsBusy] = useState(false);
  const [prodName, setProdName] = useState("");
  const [prodCat, setProdCat] = useState<any[]>([]);
  const [prodDesc, setProdDesc] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [prodWeight, setProdWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState<any>(null);
  const [inStockQty, setInStockQty] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [prodImgUrls, setProdImgUrls] = useState<any>([]);
  const [tageSelector, setTagSelector] = useState<Array<string>>([]);
  const [country, setCountry] = useState<string>("Ghana");
  const [editCategoryName, setEditCategoryName] = useState<string>();
  const [editFetching, setEditFetching] = useState<boolean>(true);
  const [EditImages, setEditImages] = useState<any>([]);
  //useReducer

  const [State, dispatchFunction] = useReducer(ReducerFunction, initialValues);

  const handleCreateProduct = async () => {
    if (
      !prodName ||
      prodCat?.length === 0 ||
      !prodDesc ||
      !originalPrice ||
      !salePrice ||
      !discountPercent ||
      !weightUnit ||
      !prodWeight ||
      !inStockQty ||
      !country ||
      !tageSelector
    ) {
      message.warning("Please fill out all fields.");
      return;
    }
    if (State.imageUrls.length < 3) {
      message.warning("Upload at least 3 product images");
      return;
    }

    try {
      const prod_data = {
        name: prodName,
        images: State.items.map((objs: any) => objs.url),
        weight: parseFloat(prodWeight),
        weightUnit: weightUnit,
        categoryId: prodCat?.map((item: any) => item?.value),
        description: prodDesc,
        productDate: today.toISOString(),
        stock: parseInt(inStockQty),
        tags: tageSelector,
        salePrice: parseFloat(salePrice),
        originalPrice: parseFloat(originalPrice),
        allowedDiscount: parseInt(discountPercent),
        country:
          country === "Ghana"
            ? "65d4b85e9ae65ff72c90900e"
            : "65d4b85e9ae65ff72c9091d6",
      };
      console.log("prodData:", prod_data);

      setIsBusy(true);

      const results = await BaseService.post_api(
        "/robocentre/product",
        prod_data
      );
      const response = await results.data;
      console.log("The response is ", response);
      setIsBusy(false);
      handleClose();
      handleReFetch();
      message.success("Product created Successfully !!");
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      message.error(error?.response?.data?.error);
      setIsBusy(false);
    }
  };

  const TagSelectHandler = (tageArray: Array<string>) => {
    console.log("select data", tageArray);
    setTagSelector(tageArray);
  };

  const CountrySelectHandler = async (myCountry: string) => {
    try {
      console.log(myCountry);

      const results = await BaseService.get_api(
        `/robocentre/country?filter={"country":"${myCountry}"}`
      );

      const { payload } = results.data;

      console.log("payload is ", payload);
      if (payload.length > 0 && payload[0].country === myCountry) {
        setCountry("Ghana");
      } else {
        setCountry("Other");
      }
    } catch (error: any) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(State.items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    dispatchFunction({ type: "updateItems", payload: reorderedItems });
  };

  useEffect(() => {
    console.log("megaState", State);
  }, [State]);

  return (
    <>
      <Modal
        open={isOpened}
        onCancel={handleClose}
        footer={null}
        maskClosable={false}
        keyboard={false}
        closeIcon={false}
        centered
        width={"60%"}
      >
        <Spin spinning={isBusy}>
          <div className="px-8 text-text_deep h-[500px] overflow-y-auto">
            <div className="flex justify-between items-center">
              <p className="text-[24px]">Add Product</p>

              <IoCloseOutline
                className="h-[20px] w-[20px] cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <hr />

            <div className="gap-4 flex mt-[20px]">
              <section className="basis-3/5 border-[1px] rounded-md border-dashed border-[#d9d9d9] h-[300px]">
                <div className="w-full h-full">
                  <UploadComponent url={State.items[0].url} />
                </div>
              </section>
              <section className=" w-full h-[320px] grid grid-rows-3 grid-cols-1 ">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {State.items.map((item: any, index: any) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <main
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  margin: "10px",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {
                                  <item.component
                                    id={item.id}
                                    dispatchFunction={dispatchFunction}
                                    MegaState={State}
                                    prodName={prodName}
                                    productId={productId}
                                  />
                                }
                              </main>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </section>
            </div>

            <div>
              <p>
                You need to add at least 4 image .Comply with the background
                colour standard. Image size should be <span className="text-default_blue font-[500]">1220px X 1220px</span>
              </p>
            </div>

            <div>
              <div className="mt-[15px] flex    items-center w-full ">
                <div className="basis-1/2">Country</div>
                <div className="w-full">
                  <SelectOnyCountry
                    onCountrySelect={CountrySelectHandler}
                    country={country}
                  />
                </div>
              </div>
              <div className="mt-[20px] flex    items-center">
                <p className="basis-1/2">Product Name</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] text-text_deep border-1 border-outline_deep placeholder-outline_light"
                    value={prodName}
                    onChange={(e) => setProdName(e.target.value)}
                    placeholder="Product name"
                  />
                </div>
              </div>
              <div className="mt-[15px] flex  items-center">
                <p className="basis-1/2">Origional Price</p>
                <div className="w-full flex gap-2 items-center">
                  <div>
                    <Select
                      className="h-[40px] w-full  placeholder-outline_light"
                      options={[
                        { label: "GHC", value: "Ghana" },
                        { label: "USD", value: "Other" },
                      ]}
                      onChange={(selected) => setCountry(selected)}
                      value={country}
                      placeholder="Currency"
                    />
                  </div>
                  <div>
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={originalPrice}
                      onChange={(e) => setOriginalPrice(e.target.value)}
                      placeholder="Original price"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Sale Price</p>
                <div className="w-full flex gap-2 items-center">
                  <div>
                    <Select
                      className="h-[40px] w-full  placeholder-outline_light"
                      options={[
                        { label: "GHC", value: "Ghana" },
                        { label: "USD", value: "Other" },
                      ]}
                      onChange={(selected) => setCountry(selected)}
                      value={country}
                      placeholder="Currency"
                    />
                  </div>
                  <div>
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={salePrice}
                      onChange={(e) => setSalePrice(e.target.value)}
                      placeholder="Sale price"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Discount Allowed</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                    value={discountPercent}
                    onChange={(e) => setDiscountPercent(e.target.value)}
                    placeholder="Discount allowed (%)"
                  />
                </div>
              </div>
              <div className=" flex items-center">
                <p className="basis-1/2">Weight</p>
                <div className="flex w-full gap-2 items-center">
                  <div className="mt-[15px]">
                    <Select
                      className="h-[40px] w-full"
                      options={[
                        { label: "Kilograms (Kg)", value: "kg" },
                        { label: "Grams (g)", value: "g" },
                      ]}
                      onChange={(selected) => setWeightUnit(selected)}
                      value={weightUnit}
                      placeholder="Weight unit"
                      allowClear
                    />
                  </div>
                  <div className="mt-[15px]">
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={prodWeight}
                      onChange={(e) => setProdWeight(e.target.value)}
                      placeholder="Product weight"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Category</p>
                <div className="w-full">
                  <ProductCatMultiSelect
                    onChange={(selected) => setProdCat(selected)}
                  />
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Description</p>
                <div className="w-full">
                  <Input.TextArea
                    className="w-full border-1 border-outline_deep placeholder-outline_light"
                    value={prodDesc}
                    onChange={(e) => setProdDesc(e.target.value)}
                    placeholder="Product Description"
                  />
                </div>
              </div>

              <div className="mt-[15px] flex  items-center">
                <p className="basis-1/2">In Stock</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                    value={inStockQty}
                    onChange={(e) => setInStockQty(e.target.value)}
                    placeholder="Stock Quantity"
                  />
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Tags</p>
                <div className="w-full">
                  <MultipleSelect
                    productId={productId}
                    tageSelector={tageSelector}
                    onTageSelect={TagSelectHandler}
                  />
                </div>
              </div>
            </div>
            <hr className="my-[16px]" />
            <div className="mt-[15px] flex justify-end gap-[30px] items-center">
              <button className="text-red-500" onClick={handleClose}>
                Cancel
              </button>

              <button
                className="px-5 py-2 h-[40px] rounded-[30px] bg-default_blue text-white shadow-sm"
                onClick={handleCreateProduct}
              >
                {"Create Product"}
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateHardwareProduct;
