import React, { useEffect, useState } from "react";
import Select from "react-select";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";

interface theProps {
    defaultValue?: any;
    onChange: (selected: any) => void;
}

const ProductCatMultiSelect = ({ onChange, defaultValue }: theProps) => {

    const [isFetching, setIsFetching] = useState(false);
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        const getAllCategories = async () => {
            setIsFetching(true);
            try {
                const response = await BaseService.get_api(
                    `${global_variables.product_categories}`
                );
                if (response?.data) {
                    const arrangedResponse = response?.data?.payload.map((item: any) => ({
                        label: item?.name,
                        value: item?._id,
                    }));

                    setAllCategories(arrangedResponse);
                }
            } catch (error) {
                console.log("prodCatErr:", error);
            } finally {
                setIsFetching(false);
            }
        };

        getAllCategories();
    }, []);



    return (
        <>
            <Select
                isMulti
                defaultValue={defaultValue}
                options={allCategories}
                onChange={onChange}
                isDisabled={isFetching}
                isLoading={isFetching}
                placeholder="Select product categories"
                className="h-[40px] w-full border-default_blue"
            // classNamePrefix="select"
            />
        </>
    );
};

export default ProductCatMultiSelect;
